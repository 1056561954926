
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Tabs from '@/ui-components/Tabs/Tabs.vue';
import Tab from '@/ui-components/Tabs/Tab.vue';
import TabsItems from '@/ui-components/Tabs/TabsItems.vue';;
import PendingCampaigns from './PendingCampaigns/PendingCampaigns.vue';
import ClosedCampaigns from './ClosedCampaigns/ClosedCampaigns.vue';
import RunningCampaigns from './RunningCampaigns/RunningCampaigns.vue';
import DraftCampaigns from './DraftCampaigns/DraftCampaigns.vue';
import { myCampaignsCategories } from '@/statics/my-campaigns';
import { MyCampaignsCategories } from '@/store/modules/advertiser-module/types';

@Component({
  name: 'MyCampaignsTabs',
  components: {
    Tab,
    Tabs,
    TabsItems,
    PendingCampaigns,
    ClosedCampaigns,
    RunningCampaigns,
    DraftCampaigns
  },
})
export default class CampaignsTabs extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  public campaignsCategories!: MyCampaignsCategories;

  @Prop({ type: Boolean })
  public loading!: boolean;

  public activeTab: string | null = null;
  public categories = myCampaignsCategories;

  public get runningCampaigns() {
    return this.campaignsCategories.runningCampaigns;
  }

  public get pendingCampaigns() {
    return this.campaignsCategories.pendingCampaigns;
  }

  public get draftCampaigns() {
    return this.campaignsCategories.draftCampaigns;
  }

  public get closedCampaigns() {
    return this.campaignsCategories.closedCampaigns;
  }

  public async tabChanged(tabName: string) {
    this.setLastVisitedTab();
    if (this.activeTab !== tabName) {
      this.$router.push({
        hash: tabName,
      });
    }
  }

  private setLastVisitedTab() {
    this.activeTab = this.$route.hash.replace('#', '');
  }

  @Watch('$route')
  public routeChange() {
    this.setLastVisitedTab();
  }

  public async created() {
    this.setLastVisitedTab();
  }
}


import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';
import PageTitle from '@/ui-components/PageTitle/PageTitle.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import CampaignsTabs from './components/CampaignsTabs.vue';
import { sharedRouteNames } from '@/route-names/shared';
import {
  LoadMyCampaigns,
  MyCampaignsCategories,
  ResetAllCampaigns,
} from '@/store/modules/advertiser-module/types';
import { Route } from 'vue-router';
import { AdvertiserCampaign } from '@/types/campaigns';
import { advertiserRouteNames } from '@/route-names/advertiser';

@Component({
  name: 'MyCampaignsPage',
  components: { CampaignsTabs, PageTitle, IButton },
})
export default class MyCampaignsPage extends Vue {
  public loading = false;

  @Getter('closedCampaigns', {
    namespace: namespaces.AdvertiserModule,
  })
  public closedCampaigns!: AdvertiserCampaign[];

  @Getter('draftCampaigns', {
    namespace: namespaces.AdvertiserModule,
  })
  public draftCampaigns!: AdvertiserCampaign[];

  @Getter('runningCampaigns', {
    namespace: namespaces.AdvertiserModule,
  })
  public runningCampaigns!: AdvertiserCampaign[];

  @Getter('pendingCampaigns', {
    namespace: namespaces.AdvertiserModule,
  })
  public pendingCampaigns!: AdvertiserCampaign[];

  @Action('resetAllCampaigns', {
    namespace: namespaces.AdvertiserModule,
  })
  public resetAllCampaigns!: ResetAllCampaigns;

  @Action('loadMyCampaigns', { namespace: namespaces.AdvertiserModule })
  public loadMyCampaigns!: LoadMyCampaigns;

  @Watch('$route')
  public routeChange(_: Route, to: Route) {
    if (
      to.name !== advertiserRouteNames.CAMPAIGN_DETAILS.name &&
      to.name !== advertiserRouteNames.CHANGE_MEDIA.name &&
      to.name !== advertiserRouteNames.MY_CAMPAIGNS.name
    ) {
      this.resetAllCampaigns();
    }
  }

  public get myCampaignsCategories(): MyCampaignsCategories {
    const {
      runningCampaigns,
      pendingCampaigns,
      closedCampaigns,
      draftCampaigns,
    } = this;
    return {
      runningCampaigns,
      pendingCampaigns,
      closedCampaigns,
      draftCampaigns,
    };
  }

  public navigateToCreateCampaign() {
    this.$router.push({
      ...sharedRouteNames.CREATE_CAMPAIGN,
    });
  }

  private addListenerToClearCampaignsOnReload() {
    window.addEventListener('beforeunload', () => {
      this.resetAllCampaigns();
    });
  }

  private async loadCampaigns() {
    this.loading = true;
    await this.loadMyCampaigns();
    this.loading = false;
  }

  public async created() {
    this.addListenerToClearCampaignsOnReload();
    await this.loadCampaigns();
  }
}


import Divider from '@/ui-components/Divider/Divider.vue';
import CampaignListItemActionButton from '@/shared-components/CampaignListItemActionButton.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AdvertiserCampaign } from '@/types/campaigns';
import CampaignSummaryDialog from '@/shared-components/CampaignSummaryDialog.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import DownloadEstimate from '@/shared-components/DownloadEstimate.vue';
import DownloadInvoice from '@/shared-components/DownloadInvoice.vue';
import { advertiserRouteNames } from '@/route-names/advertiser';
import namespaces from '@/store/namespaces';
import { Action } from 'vuex-class';
import { EditCampaign } from '@/store/modules/campaign-wizard/types';
import { sharedRouteNames } from '@/route-names/shared';

@Component({
  name: 'Actions',
  components: {
    DownloadInvoice,
    DownloadEstimate,
    CampaignSummaryDialog,
    CampaignListItemActionButton,
    Divider,
    IButton,
  },
})
export default class Actions extends Vue {
  @Prop({ type: Object, default: () => ({}) }) campaign!: AdvertiserCampaign;

  @Action('editCampaign', { namespace: namespaces.CampaignWizardModule })
  public editCampaign!: EditCampaign;

  public async edit() {
    await this.editCampaign(this.campaign);
    this.$router.push({
      ...sharedRouteNames.EDIT_CAMPAIGN,
    });
  }

  public navigateToDetailsPage() {
    const {
      campaignRequest: { ID: id },
    } = this.campaign;
    this.$router.push({
      ...advertiserRouteNames.CAMPAIGN_DETAILS,
      params: {
        id,
      },
    });
  }
}

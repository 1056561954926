import { render, staticRenderFns } from "./SkeletonLoading.vue?vue&type=template&id=509f80c8&scoped=true"
import script from "./SkeletonLoading.vue?vue&type=script&lang=ts"
export * from "./SkeletonLoading.vue?vue&type=script&lang=ts"
import style0 from "./SkeletonLoading.vue?vue&type=style&index=0&id=509f80c8&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "509f80c8",
  null
  
)

export default component.exports
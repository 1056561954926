
import { Component, Prop, Vue } from 'vue-property-decorator';
import Card from '@/ui-components/Card/Card.vue';
import Media from '@/ui-components/Media/Media.vue';
import { AdvertiserCampaign } from '@/types/campaigns';
import CampaignData from './components/CampaignData.vue';
import VerticalDivider from '../VerticalDivider/VerticalDivider.vue';

@Component({
  components: {
    Card,
    CampaignData,
    Media,
    VerticalDivider,
  },
  name: 'MyCampaignCard',
})
export default class MyCampaignCard extends Vue {
  @Prop({ required: true, type: Object }) campaign!: AdvertiserCampaign;

  public get media() {
    if (this.campaign.mediaList && this.campaign.mediaList.length) {
      const [ media ] = this.campaign.mediaList;
      return media;
    }
    return this.campaign.media;
  }
}

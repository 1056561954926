
import { Component, Emit, Vue } from "vue-property-decorator";

@Component({
  name: "CampaignListItemActionButton",
})
export default class CampaignListItemActionButton extends Vue {
  @Emit()
  public click() {
    return true;
  }
}

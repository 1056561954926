
import { Component, Vue, Prop } from 'vue-property-decorator';
import { downloadPdfFromBase64 } from '@/utils/files';
import { PendingPaymentCampaign } from '@/types/campaigns';
import { Action } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { DownloadCampaignEstimate } from '@/store/modules/campaign/types';

@Component({})
export default class DownloadEstimate extends Vue {
  @Prop({ required: true }) public campaign!: PendingPaymentCampaign;

  @Action('downloadCampaignEstimate', { namespace: namespaces.CampaignModule })
  public downloadCampaignEstimate!: DownloadCampaignEstimate;

  public loading = 0;

  public async downloadEstimate() {
    const intervalId = setInterval(() => (this.loading += 1), 300); // completes in 30 sec

    try {
      const pdf = await this.downloadCampaignEstimate(this.campaign);

      const blob = downloadPdfFromBase64(
        pdf,
        `${this.campaign.ESTIMATE_NUMBER}.pdf`
      );

      this.loading = 100;
      setTimeout(() => {
        clearInterval(intervalId);
        this.loading = 0;
      }, 1000);

      /** Open file in current window */
      // const fileURL = URL.createObjectURL(blob);
      // window.location.href = fileURL;
    } catch (err) {
      clearInterval(intervalId);
      this.loading = 0;
      /* tslint:disable-next-line */
      console.log(err);
    }
  }
}


import Divider from '@/ui-components/Divider/Divider.vue';
import CampaignListItemActionButton from '@/shared-components/CampaignListItemActionButton.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AdvertiserCampaign } from '@/types/campaigns';
import CampaignSummaryDialog from '@/shared-components/CampaignSummaryDialog.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import DownloadEstimate from '@/shared-components/DownloadEstimate.vue';
import DownloadInvoice from '@/shared-components/DownloadInvoice.vue';
import { advertiserRouteNames } from '@/route-names/advertiser';

@Component({
  name: 'Actions',
  components: {
    DownloadInvoice,
    DownloadEstimate,
    CampaignSummaryDialog,
    CampaignListItemActionButton,
    Divider,
    IButton,
  },
})
export default class Actions extends Vue {
  @Prop({ type: Object, default: () => ({}) }) campaign!: AdvertiserCampaign;

  public navigateToDetailsPage() {
    const {
      campaignRequest: { ID: id },
    } = this.campaign;
    this.$router.push({
      ...advertiserRouteNames.CAMPAIGN_DETAILS,
      params: {
        id,
      },
    });
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator';
import { AdvertiserCampaign } from '@/types/campaigns';
import MyCampaignCard from '@/ui-components/MyCampaignCard/MyCampaignCard.vue';
import Actions from './Actions.vue';
import CampaignsTab from '../CampaignsTab.vue';

@Component({
  name: 'DraftCampaigns',
  components: {
    MyCampaignCard,
    Actions,
    CampaignsTab,
  },
})
export default class DraftCampaigns extends Vue {
  @Prop({ type: Array, default: () => [] }) campaigns!: AdvertiserCampaign[];
  @Prop({ type: Boolean }) loading!: boolean;
}


import { Component, Prop, Vue } from 'vue-property-decorator';
import Media from '@/ui-components/Media/Media.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import IDialog from '@/ui-components/IDialog/IDialog.vue';
import { CampaignSummary } from '@/types/campaigns';

@Component({
  components: { Media, IButton, IDialog },
})
export default class CampaignSummaryDialog extends Vue {
  @Prop({ type: Object, default: () => ({}) }) public summary!: CampaignSummary;

  public isOpen = false;

  public open() {
    this.isOpen = true;
  }

  public close() {
    this.isOpen = false;
  }
}

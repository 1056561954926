
import { Component, Prop, Vue } from 'vue-property-decorator';
import TabItem from '@/ui-components/Tabs/TabItem.vue';
import NoCampaigns from '@/shared-components/NoCampaigns.vue';
import SkeletonLoading from './SkeletonLoading.vue';
import { AdvertiserCampaign } from '@/types/campaigns';

@Component({
  name: 'CampaignsTab',
  components: {
    TabItem,
    NoCampaigns,
    SkeletonLoading,
  },
})
export default class CampaignsTab extends Vue {
  @Prop({ type: Array, default: () => [] })
  public campaigns!: AdvertiserCampaign[];

  @Prop({ type: Boolean }) loading!: boolean;

  @Prop({ type: String })
  public categoryName!: string;
}
